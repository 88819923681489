import {
  add,
  Duration,
  endOfDay,
  endOfMonth as dateFnsEndOfMonth,
  format as dateFnsFormat,
  getDate,
  isEqual,
  parse as dateFnsParse,
  set,
  setDate,
  startOfDay,
  startOfMonth as dateFnsStartOfMonth,
  sub,
  Locale,
  startOfMinute,
} from 'date-fns';
import { de, enUS as en, fr, it } from 'date-fns/locale';

import { DEFAULT_LANG, Lang } from '@sbiz/common';
import { isKeyInObject } from '@sbiz/util-common';

import { WEEK_DAYS } from './constants';
import { DateArg, DateRange } from './types';

const LOCALES = { de, en, fr, it } as const satisfies Record<Lang, Locale>;

export function formatDate(date: DateArg | null | undefined, format: string, lang?: Lang) {
  return date ? dateFnsFormat(date, format, lang && { locale: getLocale(lang) }) : '';
}

export function getDateFromTime(time: string, refDate?: DateArg) {
  const date = refDate ?? new Date();
  const dateValues = parseTime(time);

  return startOfMinute(set(date, dateValues));
}

export function getDateInFuture(amount?: number, unit?: keyof Duration, refDate?: DateArg) {
  return add(refDate ?? new Date(), { [unit ?? 'days']: amount ?? 1 });
}

export function getDateInFutureDuration(duration: Duration, refDate?: DateArg) {
  return add(refDate ?? new Date(), duration);
}

export function getDateInPast(amount?: number, unit?: keyof Duration, refDate?: DateArg) {
  return sub(refDate ?? new Date(), { [unit ?? 'days']: amount ?? 1 });
}

export function getDateInPastDuration(duration: Duration, refDate?: DateArg) {
  return sub(refDate ?? new Date(), duration);
}

export function getDayOfMonth(date?: DateArg) {
  return getDate(date ?? new Date());
}

export function getDayOfWeek(index: number) {
  return WEEK_DAYS[index % WEEK_DAYS.length];
}

export function getTimestamps(date?: Date) {
  const timestamp = date ?? new Date();
  return { createdAt: timestamp, updatedAt: timestamp };
}

export function getLocale(lang: string) {
  const formattedLang = lang.substring(0, 2);
  const locale = isKeyInObject(formattedLang, LOCALES) ? formattedLang : DEFAULT_LANG;
  return LOCALES[locale];
}

export function getDateRange(config: { end?: Date; start: Date }): DateRange;
export function getDateRange(config: { duration: Duration; end?: Date }): DateRange;
export function getDateRange(config: { duration: Duration; start: Date }): DateRange;
export function getDateRange(
  config:
    | { duration?: never; end?: Date; start: Date }
    | { duration: Duration; end?: Date; start?: never }
    | { duration: Duration; end?: never; start: Date },
) {
  if (config.duration) {
    if (config.start) {
      return {
        end: endOfDay(add(config.start, config.duration)),
        start: startOfDay(config.start),
      };
    }

    const end = config.end ?? new Date();
    return { end: endOfDay(end), start: startOfDay(sub(end, config.duration)) };
  }

  return { end: endOfDay(config.end ?? new Date()), start: startOfDay(config.start) };
}

export function getEndOfMonth(date?: DateArg) {
  return dateFnsEndOfMonth(date ?? new Date());
}

export function getEndOfPreviousDay(date?: DateArg) {
  return endOfDay(getDateInPast(1, 'days', date));
}

export function getStartOfMonth(date?: DateArg) {
  return dateFnsStartOfMonth(date ?? new Date());
}

export function getStartOfNextDay(date: DateArg) {
  return startOfDay(add(date, { days: 1 }));
}

export function isEndOfMonth(date?: DateArg) {
  return isEqual(date ?? new Date(), getEndOfMonth(date));
}

export function isStartOfMonth(date?: DateArg) {
  return isEqual(date ?? new Date(), getStartOfMonth(date));
}

export function parseDate(dateString: string, format: string) {
  return dateFnsParse(dateString, format, new Date());
}

export function parseTime(time: string) {
  const [hoursString, minutesString, ...rest] = time.split(':');

  if (rest.length) {
    throw new Error(`Invalid time string : "${time}"`);
  }

  const parts = [hoursString, minutesString].map((part) => Number(part));

  if (parts.some((part) => Number.isNaN(part))) {
    throw new Error(`Invalid time string : "${time}"`);
  }

  const [hours, minutes] = parts;
  return { hours, minutes } as const;
}

export function setDayOfMonth(day: number, date?: DateArg) {
  return setDate(date ?? new Date(), day);
}
