import { SvgIcon, SvgIconProps } from '@mui/material';

export function InvoiceUnpayIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g transform="matrix(0.5,0,0,0.5,0.5,0)">
        <path d="m 18,20 h 20 v 4 H 18 Z m 0,8 h 20 v 4 H 18 Z m 0,8 h 20 v 4 H 18 Z M 34.83,0 H 10 v 2 c 2.25,0 4.31,0.75 5.98,2 H 30 V 16 H 42 V 44 H 14 V 21.16 C 12.77,21.7 11.42,22 10,22 V 48 H 46 V 11.17 Z M 34,12 V 4.83 L 41.17,12 Z" />
        <path
          d="M 15.98,4 C 14.31,2.75 12.24,2 10,2 4.48,2 0,6.48 0,12 c 0,5.52 4.48,10 10,10 1.42,0 2.77,-0.3 4,-0.84 C 17.53,19.62 20,16.1 20,12 20,8.72 18.42,5.82 15.98,4 Z m -1.22,10.64 c 0.59,0.59 0.59,1.54 0,2.12 -0.29,0.29 -0.68,0.44 -1.06,0.44 -0.38,0 -0.77,-0.15 -1.06,-0.44 L 10,14.12 7.36,16.76 C 7.07,17.05 6.68,17.2 6.3,17.2 5.92,17.2 5.53,17.05 5.24,16.76 4.65,16.17 4.65,15.22 5.24,14.64 L 7.88,12 5.24,9.36 C 4.65,8.77 4.65,7.82 5.24,7.24 5.83,6.66 6.78,6.65 7.36,7.24 L 10,9.88 12.64,7.24 c 0.59,-0.59 1.54,-0.59 2.12,0 0.58,0.59 0.59,1.54 0,2.12 L 12.12,12 Z"
          fill="#dd4e47"
        />
      </g>
    </SvgIcon>
  );
}
