import {
  CompanyJson,
  FeatureFlag,
  Order,
  Permissions,
  PermissionsScope,
  Statement as StatementType,
} from '@sbiz/business';
import { Value, ValueProperty } from '@sbiz/common';
import { Json } from '@sbiz/util-mongodb';

import { Benefit } from './benefit';
import { EmployeeInvitation } from './employee-invitation';
import { GiftCard } from './gift-card';
import { Manager } from './manager';
import { Profile } from './profile';
import { Statement } from './statement';

export type ApiData = string | object;

export type ApiRecords = {
  benefit: Benefit;
  benefitAdmin: Benefit;
  company: CompanyJson;
  employee: EmployeeInvitation;
  featureFlag: FeatureFlag;
  giftCard: GiftCard;
  manager: Manager;
  order: Order;
  orderAdmin: Order;
  permissions: Json<Permissions>;
  permissionsAdmin: Json<Permissions>;
  profile: Profile;
  profileAdmin: Profile;
  purchases: Json<StatementType>;
  statement: Statement;
};
export type ApiRecord<T extends ResourceType> = T extends keyof ApiRecords ? ApiRecords[T] : string | object;

export const API_RESOURCES_DEFINITION = {
  addresses: { path: 'v1/addresses' },
  auth: { path: 'v1/auth' },
  benefit: { deletionField: 'smoodBusinessBenefit.deletedAt', path: 'v1/benefits', permissionsScope: 'benefits' },
  benefitAdmin: {
    deletionField: 'smoodBusinessBenefit.deletedAt',
    path: 'v1/admin/benefits',
    permissionsScope: 'adminOrders',
  },
  checkDistribution: { path: 'v1/checks/distributions', permissionsScope: 'smoodChecks' },
  check: { path: 'v1/checks', permissionsScope: 'smoodChecks' },
  company: { path: 'v1/companies', permissionsScope: 'companySelection' },
  companyAdmin: { path: 'v1/admin/companies', permissionsScope: 'adminCompanies' },
  costCenter: { path: 'v1/companies/cost-centers', permissionsScope: 'companyCostCenter' },
  costCenterAdmin: { path: 'v1/admin/companies/cost-centers', permissionsScope: 'adminCompanies' },
  datatrans: { path: 'v1/datatrans' },
  datatransAdmin: { path: 'v1/admin/datatrans', permissionsScope: 'adminDatatrans' },
  employee: { path: 'v1/employees', permissionsScope: 'employees' },
  employeeInvitation: { path: 'v1/employees/invitations', permissionsScope: 'employees' },
  employeeInvitationAdmin: { path: 'v1/admin/employees/invitations', permissionsScope: 'adminEmployees' },
  featureFlag: { path: 'v1/feature-flags' },
  feedback: { path: 'v1/user-feedbacks' },
  files: { path: 'v1/files' },
  giftCard: { path: 'v1/gift-cards', permissionsScope: 'giftCards' },
  giftCardAdmin: { path: 'v1/admin/gift-cards', permissionsScope: 'adminGiftCardRequests' },
  invoice: { path: 'v1/invoices', permissionsScope: 'accounting' },
  invoiceAdmin: { path: 'v1/admin/invoices', permissionsScope: 'adminOpenInvoices' },
  manager: { path: 'v1/managers', permissionsScope: 'managers' },
  managerAdmin: { path: 'v1/admin/managers', permissionsScope: 'adminPermissions' },
  managerInvitation: { path: 'v1/manager-invitations', permissionsScope: 'managers' },
  managerInvitationAdmin: { path: 'v1/admin/manager-invitations', permissionsScope: 'adminPermissions' },
  monthlyStatementAdmin: { path: 'vnext/admin/statements', permissionsScope: 'adminOpenInvoices' },
  offer: { path: 'v1/offers', permissionsScope: 'adminCompanies' },
  order: { path: 'v1/orders', permissionsScope: 'orders' },
  orderAdmin: { path: 'v1/admin/orders' },
  permissions: { path: 'v1/permissions', permissionsScope: 'permissions' },
  permissionsAdmin: { path: 'v1/admin/permissions', permissionsScope: 'adminPermissions' },
  profile: { path: 'v1/profiles', permissionsScope: 'profiles' },
  profileAdmin: { path: 'v1/admin/profiles', permissionsScope: 'adminOrders' },
  purchases: { path: 'v1/statements', permissionsScope: 'purchases' },
  statement: { path: 'v1/statements', permissionsScope: 'accounting' },
  statementAdmin: { path: 'v1/admin/statements', permissionsScope: 'adminOpenInvoices' },
} as const satisfies Record<string, { deletionField?: string; path: string; permissionsScope?: PermissionsScope }>;
type ApiResources = typeof API_RESOURCES_DEFINITION;
export const API_RESOURCES: Readonly<{ [P in ResourceType]: ResourceDefinition }> = API_RESOURCES_DEFINITION;

export type ResourceDeletionField = ValueProperty<ApiResources, 'deletionField'>;
export type ResourcePath = Value<ApiResources>['path'];
export type ResourceType = keyof ApiResources;
export type ResourceDefinition = {
  deletionField?: ResourceDeletionField;
  path: ResourcePath;
  permissionsScope?: PermissionsScope;
};
